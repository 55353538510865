import { handleLinkByType } from "@/helpers/common/handleLinkByType";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import Link from "next/link";
import Image from "@/components/global/Image";
import MainSliderCard from "../cards/MainSliderCard";

const Banners = ({ data, locale, isMobile }) => {
  const activeSlider = data?.sliders?.value?.filter((item) => item?.active);
  const activeBanner = data?.banners?.value.filter((item) => item?.active);

  const imgSrcHandler = (image) => {
    const img = image?.[`${isMobile ? "mobile-" : ""}img-${locale}`];
    return img && img.startsWith("http")
      ? img
      : `${process.env.NEXT_PUBLIC_IMG_BASEURL}/json/${img}`;
  };

  return (
    <section className="grid grid-cols-1 gap-6 sm:grid-cols-2">
      <Swiper
        modules={[Autoplay, Pagination]}
        autoplay={{ delay: 4000, disableOnInteraction: false }}
        key={locale}
        pagination={{ clickable: true }}
        dir={locale === "en" ? "ltr" : "rtl"}
        className="w-full banner-swiper"
        loop
      >
        {activeSlider?.map((item, i) => {
          return (
            <SwiperSlide key={i}>
              <MainSliderCard slider={item} />
            </SwiperSlide>
          );
        })}
      </Swiper>

      <div className="flex gap-2 overflow-x-auto pb-1 sm:grid sm:grid-cols-2 sm:auto-rows-auto sm:gap-x-6 lg:gap-x-8 sm:gap-y-6">
        {[0, 2, 3, 1].map((index) => {
        // {(isMobile ? [0, 1, 2, 3] : [0, 2, 3, 1]).map((index) => {
          const isTall =  index === 0 || index === 3;
          // const isTall = isMobile ? index === 0 || index === 2 : index === 0 || index === 3;
          return (
            <Link
              key={index}
              href={handleLinkByType(activeBanner?.[index]?.url, activeBanner?.[index]?.type)}
              className={`relative group  overflow-hidden flex-shrink-0 aspect-square max-sm:h-40 sm:aspect-auto sm:w-full ${
                isTall ? "sm:row-span-2" : ""
              }`}
            >
              <div className="relative w-full h-full">
                <Image
                  src={imgSrcHandler(activeBanner?.[index])}
                  width={500}
                  height={500}
                  className="w-full h-full"
                  loading="eager"
                  quality={100}
                  alt={activeBanner?.[index]?.[`title-${locale}`] || "Banner Image"}
                />
              </div>
              <HoverBanner subtitle={activeBanner?.[index]?.[`title-${locale}`]} />
            </Link>
          );
        })}
      </div>
    </section>
  );
};

export default Banners;

const HoverBanner = ({ title = "", subtitle = "", description = "" }) => {
  return (
    <div className="absolute top-0 left-0 z-50 w-full h-full transition-all duration-500 bg-black opacity-0 group-hover:opacity-100 bg-opacity-60">
      <div className="flex flex-col justify-center h-full gap-1 text-sm text-white sm:text-lg sm:gap-2 ms-2 sm:ms-10">
        <span>{subtitle}</span>
        <h1 className="text-lg font-bold md:text-xl text-primary">{title}</h1>
        <span>{description}</span>
      </div>
    </div>
  );
};
